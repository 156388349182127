import React, { useContext, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { MenskundeContext } from '../components/MenskundeLayoutWrapper';

import bgVideo from './../assets/videos/menskunde_bg.mp4';
import setFullscreenVideoAspectRatio from '../helpers/VideoHelper';

function PageMenskundeTopic() {
    const navigate = useNavigate();

    const videoEl = useRef(null);

    const [isLoading, setIsLoading] = useState(true);

    const { topicSlug } = useParams();
    const { getTopic, topics } = useContext(MenskundeContext);
    const [topic, setTopic] = useState(null);
    const topicEl = useRef(null);

    useEffect(() => {
        if (!topicSlug) return;

        const topic = getTopic(topicSlug);

        if (topic === null) {
            if (! topics) navigate('/menskunde');
            return;
        }

        setTopic(topic);
        setIsLoading(false);
    }, [topics, getTopic, topicSlug, navigate]);

    function resize() {
        if (videoEl.current === null) return;
        setFullscreenVideoAspectRatio(videoEl.current, 16 / 9);
    }

    resize();

    useLayoutEffect(() => {
        if (videoEl.current === null) return;
        videoEl.current.currentTime = 2.3;

        resize();
        window.addEventListener('resize', resize);

        const interval = setInterval(() => {
            if (videoEl.current.currentTime > 6) {
                videoEl.current.currentTime = 2.3;
            }
        }, 100);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        if (topics.length === 0) return;
        videoEl.current.play();
    }, [topics]);

    const goToCard = (slug) => {
        return navigate(`/menskunde/kaart/${slug}`);
    };

    return (<>
        <div className="top-spacer"></div>

        {!isLoading && !!topic ?
            <>
                <div className="topic-posts" ref={topicEl}>
                    <ul className="page-vak__card-grid">
                        {topic.cards.map((card, index) => {
                            return (
                                <li key={`page-menskunde-topic-grid-card-${index}`} className="page-vak__grid-card page-vak__grid-card--menskunde">
                                    <button className="page-vak__grid-card__btn" type="button" onClick={() => goToCard(card.slug)}>
                                        <span className="page-vak__grid-card__vak">Menskunde</span>
                                        <span className="page-vak__grid-card__title heading:3">{card.name}</span>
                                        <div className="page-vak__grid-card__excerpt default-styles"><p>{card.excerpt}</p></div>
                                        <div className="page-vak__grid-card__icon"></div>
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </>
        :
            <div className="loading-msg">Laden..</div>
        }

        <div className="menskunde-topics__bg-wrapper">
            <video ref={videoEl} src={bgVideo} type="video/mp4" playsInline muted></video>
        </div>
    </>);
}

export default PageMenskundeTopic;