import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';

import VakcurListCard from './VakcurListCard';
import VakcurFilter from './VakcurFilter';

import { UserContext } from '../App';
import { MainContext } from './MainLayoutWrapper';
import CustomSelect from './CustomSelect';
import VsPedagogiekListCard from './VsPedagogiekListCard';
import OntwikkelingsmomentListCard from './OntwikkelingsmomentListCard';
import MenskundeListCard from './MenskundeListCard';

function SearchModal({ settings, show, setShow, searchQuery, setSearchQuery, searchScope, setSearchScope }) {
    const userContext = useContext(UserContext);
    const mainContext = useContext(MainContext);

    const [cards, setCards] = useState([]);

    const searchInputEl = useRef(null);
    const abortController = useRef(null);

    const keyUp = useCallback(e => {
        if (e.key.toLowerCase() === 'escape') setShow(false);
    }, [setShow]);

    const getResults = useCallback(() => {
        searchInputEl.current.value = searchQuery;

        if (searchQuery === '' && mainContext.getActiveSearchFilterCount() === 0) {
            setCards([]);
            return;
        }

        if (abortController.current !== null) abortController.current.abort();
        abortController.current = new AbortController();

        fetch(settings.apiBaseUrl + 'search', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({
                searchQuery: searchQuery,
                searchScope: searchScope,
                activeFilters: mainContext.activeSearchFilters
            }),
            signal: abortController.current.signal
        })
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    setCards(result.cards);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else alert('Oeps, er ging iets fout bij het laden van de resultaten. Probeer het nogmaals.');
                }
            })
            .catch(err => {
                if (err.name === 'AbortError') return console.log(err);
                console.error(err);
            });
    }, [searchQuery, searchScope, settings.apiBaseUrl, userContext, mainContext]);

    useEffect(() => {
        if (show) searchInputEl.current.focus();
    }, [show]);

    useEffect(() => {
        getResults();
    }, [mainContext.activeFilters, getResults]);

    useEffect(() => {
        const timeout = setTimeout(getResults, 500);
        return () => {
            clearTimeout(timeout);
            if (abortController.current !== null) abortController.current.abort();
        }
    }, [searchQuery, getResults, abortController]);

    useEffect(() => {
        window.addEventListener('keyup', keyUp);
        return () => window.removeEventListener('keyup', keyUp);
    }, [keyUp]);

    return (<>
        <div className={'vakcur-search-modal' + (show ? ' show' : '')}>
            <div className="vakcur-search-modal__bg" onClick={() => setShow(false)}></div>

            <div className="vakcur-search-modal__inner">
                <button className="vakcur-search-modal__close-btn" type="button" onClick={() => setShow(false)}>Sluiten</button>

                <div className="vakcur-search-modal__top">
                    <div className="vakcur-search-modal__search-bar">
                        <input type="text" ref={searchInputEl} placeholder="Zoekopdracht" onChange={(e) => setSearchQuery(e.target.value)} />

                        <CustomSelect placeholderText="Waar wil je zoeken?" selectedOptionSlug={searchScope} setSelectedOptionSlug={setSearchScope} optionBtnClasses={''} options={[
                            { title: 'Zoek overal', slug: 'global' },
                            { title: 'Vakcurriculum', slug: 'vakcurriculum' },
                            { title: 'Menskunde', slug: 'menskunde' },
                            { title: 'VS-pedagogiek', slug: 'vs-pedagogiek' },
                            { title: 'Ontwikkelingsmoment', slug: 'ontwikkelingsmoment' }
                        ]} />
                    </div>

                    { searchScope === 'vakcurriculum' && <VakcurFilter getActiveFilterCount={mainContext.getActiveFilterCount} isSearch={true} /> }
                </div>

                <div className="vakcur-search-modal__content-wrapper">
                    <span className="vakcur-search-modal__results-title">Resultaten:</span>
                    <div className="vakcur-search-modal__grid">
                        {!!cards.length ?
                            cards.map(card => {
                                switch(card.lpb_type) {
                                    case 'vs-pedagogiek' : return <VsPedagogiekListCard key={`search-result-card-${card.id}`} card={card} callback={() => setShow(false)} showThumbnail={false} altBgColor={true} />
                                    case 'ontwikkelingsmoment' : return <OntwikkelingsmomentListCard key={`search-result-card-${card.id}`} card={card} callback={() => setShow(false)} showThumbnail={false} altBgColor={true} />
                                    case 'menskunde' : return <MenskundeListCard key={`search-result-card-${card.id}`} card={card} callback={() => setShow(false)} showThumbnail={false} altBgColor={true} />
                                    default : return <VakcurListCard key={`search-result-card-${card.id}`} card={card} callback={() => setShow(false)} showThumbnail={false} altBgColor={true} />
                                }
                            })
                        :
                            <div className="vakcur-search-modal__no-results default-styles"><p>{ searchQuery === '' && mainContext.getActiveSearchFilterCount() === 0 ? 'Voer een zoekopdracht in of gebruik de filters.' : 'Er zijn geen resultaten gevonden voor de huidige filtercombinatie.' }</p></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default SearchModal;
