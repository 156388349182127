import React, { useRef, useLayoutEffect, useContext, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import gsap from 'gsap';

import setFullscreenVideoAspectRatio from '../helpers/VideoHelper';
import bgVideo from './../assets/videos/klastak_bg.mp4';

import TrajectSelectModal from './../components/TrajectSelectModal';
import CardSelectModal from '../components/CardSelectModal';
import SideYearNav from '../components/SideYearNav';
import { UserContext } from '../App';
import { MainContext } from '../components/MainLayoutWrapper';

const existingVakIcons = [
    'aardrijkskunde',
    'architectuur',
    'biologie',
    'ckv',
    'duits',
    'economie',
    'engels',
    'frans',
    'geschiedenis',
    'handvaardigheid',
    'kunstgeschiedenis',
    'lichamelijke-opvoeding',
    'maatschappijleer',
    'muziek',
    'natuurkunde',
    'nederlands',
    'scheikunde',
    'sterrenkunde',
    'tekenen',
    'textiele-werkvormen',
    'toneel-drama',
    'wiskunde',
];

function PageKlas() {
    const params = useParams();

    const userContext = useContext(UserContext)
    const mainContext = useContext(MainContext);

    const vakkenEl = useRef(null);
    const videoWrapperEl = useRef(null);
    const videoEl = useRef(null);
    const reverseBtnEl = useRef(null);
    const forwardBtnEl = useRef(null);

    const [showIcons, setShowIcons] = useState(false);

    const klasSlug = params.klas || null;

    const hasUserSeenKlasInfoModal = useCallback((klasSlug) => {
        if (klasSlug === null) return false;

        const currentUser = userContext.getCurrentUser();
        const value = localStorage.getItem(`u${currentUser.id}_vakcurriculum_klas_info_modal_views`);

        if (value === null) return false;
        return value.includes(klasSlug);
    }, [userContext]);

    const [showTrajectSelect, setShowTrajectSelect] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(!hasUserSeenKlasInfoModal(klasSlug));
    const [showCardSelectModal, setShowCardSelectModal] = useState(false);
    const [cardSelectCards, setCardSelectCards] = useState([]);

    const currentVakcurKlas = mainContext.klassen.find(klas => parseInt(klas.slug) === parseInt(klasSlug));
    const currentVakkenFiltered = getCurrentVakkenArrayFiltered();

    const activeAnimation = useRef(null);

    const setViewCentered = useCallback(() => {
        if (videoWrapperEl.current === null) return;

        killActiveAnimation();

        const xOffset = (videoWrapperEl.current.offsetWidth / 2) - (window.innerWidth / 2);
        activeAnimation.current = gsap.set(videoWrapperEl.current, { x: -xOffset, ease: 'power2.out' });
    }, []);

    const resize = useCallback(() => {
        setViewCentered();

        if (videoEl.current === null) return;
        setFullscreenVideoAspectRatio(videoEl.current.parentElement, 16 / 9);
    }, [setViewCentered]);

    resize();

    function getCurrentCardsFiltered() {
        if (!currentVakcurKlas) return [];

        const kaarten = Object.values(currentVakcurKlas.kaarten).filter(kaart => {
            if (mainContext.activeFilters.vak !== null && !kaart.vakken.includes(mainContext.activeFilters.vak)) return false;
            if (mainContext.activeFilters.traject !== null && Object.values(currentVakcurKlas.trajecten).length > 0 && !kaart.trajecten.includes(mainContext.activeFilters.traject)) return false;
            if (mainContext.activeFilters.lestype !== null && !kaart.lestypen.includes(mainContext.activeFilters.lestype)) return false;

            if (mainContext.activeFilters.tijdvak !== null && !kaart.tijdvakken.includes(mainContext.activeFilters.tijdvak)) return false;
            if (mainContext.activeFilters.vakoverstijging !== null && !kaart.vakoverstijgingen.includes(mainContext.activeFilters.vakoverstijging)) return false;
            if (mainContext.activeFilters.huiswerk !== null && !kaart.huiswerk.includes(mainContext.activeFilters.huiswerk)) return false;
            if (mainContext.activeFilters.leerfocus !== null && !kaart.leerfocus.includes(mainContext.activeFilters.leerfocus)) return false;
            if (mainContext.activeFilters.afsluiting !== null && !kaart.afsluitingen.includes(mainContext.activeFilters.afsluiting)) return false;
            if (mainContext.activeFilters.pta !== null && !kaart.pta.includes(mainContext.activeFilters.pta)) return false;
            if (mainContext.activeFilters.portfolio !== null && !kaart.portfolio.includes(mainContext.activeFilters.portfolio)) return false;

            return true;
        });

        return kaarten;
    }

    function getCurrentVakkenArrayFiltered() {
        if (!currentVakcurKlas) return [];

        const cardsFiltered = getCurrentCardsFiltered();
        if (cardsFiltered.length === 0) return [];

        const vakken = {};

        cardsFiltered.forEach(kaart => {
            kaart.vakken.forEach(vakSlug => {
                if (!vakken[vakSlug]) vakken[vakSlug] = {
                    vak: mainContext.vakken.find(vak => vak.slug === vakSlug),
                    kaarten: [kaart]
                };
                else {
                    vakken[vakSlug].kaarten.push(kaart);
                }
            });
        });

        return Object.values(vakken);
    }

    function trajectSelected(trajectSlug) {
        if (trajectSlug !== null) {
            mainContext.helperFunctions.setTrajectFilter(trajectSlug);
        }
        else {
            setShowTrajectSelect(false);
            mainContext.helperFunctions.nav('/vakcurriculum');
        }
    }

    function handleVakClick(cards) {
        setCardSelectCards(cards);
        setShowCardSelectModal(true);
    }

    function killActiveAnimation() {
        if (activeAnimation.current) {
            activeAnimation.current.kill();
            activeAnimation.current = null;
        }
    }

    function moveViewReversed() {
        killActiveAnimation();

        const bounds = videoWrapperEl.current.getBoundingClientRect();
        let moveOffset = window.innerWidth / 5;

        if (Math.round(bounds.x + moveOffset) >= 0) moveOffset = (moveOffset - bounds.x) - moveOffset;

        activeAnimation.current = gsap.to(videoWrapperEl.current, { duration: 1, x: '+=' + moveOffset, ease: 'power2.out' });
    }

    function moveViewForward() {
        killActiveAnimation();

        const bounds = videoWrapperEl.current.getBoundingClientRect();
        const moveOffset = window.innerWidth / 5;

        const maxX = -(bounds.width - window.innerWidth);
        const newX = bounds.x - moveOffset >= maxX ? bounds.x - moveOffset : maxX;

        activeAnimation.current = gsap.to(videoWrapperEl.current, { duration: 1, x: newX, ease: 'power2.out' });
    }

    useEffect(() => {
        if (mainContext.activeFilters.klas !== null) {
            const klas = mainContext.klassen.find(klas => klas.slug === mainContext.activeFilters.klas);
            const trajecten = Object.values(klas.trajecten);

            setShowTrajectSelect(trajecten.length > 0 && mainContext.activeFilters.traject === null);
        }
    }, [mainContext.activeFilters.klas, mainContext.activeFilters.traject, mainContext.klassen]);

    useEffect(() => {
        if (mainContext.klassen.length > 0 && videoEl.current !== null) {
            videoEl.current.play();
            setTimeout(() => gsap.to(vakkenEl.current, { duration: 0.5, opacity: 1, ease: 'power2.inOut', onComplete: () => setShowIcons(true) }), 1700);
        }
    }, [mainContext.klassen]);

    useEffect(() => {
        setShowInfoModal(!hasUserSeenKlasInfoModal(params.klas));
    }, [params.klas, setShowInfoModal, hasUserSeenKlasInfoModal]);

    useLayoutEffect(() => {
        if (videoEl.current === null) return;

        resize();
        window.addEventListener('resize', resize);

        setViewCentered();

        const interval = setInterval(() => {
            if (videoEl.current.currentTime > 6) {
                videoEl.current.currentTime = 2.15;
            }
        }, 100);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', resize);
        };
    }, [resize, setViewCentered]);

    return (<>
        {!!currentVakcurKlas && <>
            <SideYearNav showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} />
        </>}

        {mainContext.klassen.length === 0 && <div className="loading-msg">Laden..</div>}

        <CardSelectModal show={showCardSelectModal} setShow={setShowCardSelectModal} cards={cardSelectCards} />

        <button type="button" ref={reverseBtnEl} onClick={moveViewReversed} className="page-klas__nav-btn page-klas__nav-btn--reverse">Terug</button>
        <button type="button" ref={forwardBtnEl} onClick={moveViewForward} className="page-klas__nav-btn page-klas__nav-btn--forward">Vooruit</button>

        {!!currentVakcurKlas && currentVakkenFiltered.length === 0 && <div className="page-klas__no-cards-msg">Er zijn geen resultaten gevonden voor de huidige filtercombinatie.</div>}

        <div className="page-klas__bg-wrapper">
            <div className="page-klas__bg" ref={videoWrapperEl}>
                {!!currentVakcurKlas && <>
                    <ul className={'page-klas__vakken' + (showIcons ? ' page-klas__vakken--show' : '')} ref={vakkenEl}>
                        {currentVakkenFiltered.map((item, index) => {
                            const altImage = item.vak.alt_images.length > 0 ? item.vak.alt_images[0].thumbnail : null;

                            return (
                                <li key={Math.random() + Math.random() + ''} className={`page-klas__klassen__vak vak-icon jsVakIcon ${existingVakIcons.includes(item.vak.slug) ? item.vak.slug : `vak-icon--alt vak-icon--alt-${index + 1}`}`}>
                                    <button type="button" onClick={() => handleVakClick(item.kaarten)}>
                                        {!!altImage && ! existingVakIcons.includes(item.vak.slug) && <div className="vak-icon__alt-img-wrapper"><img src={altImage} alt={item.vak.name} /></div>}
                                        <span className="vak-icon__name"><span>{item.vak.name}{process.env.REACT_APP_ENVIRONMENT === 'development' && (' (' + item.kaarten.length + ')')}</span></span>
                                    </button>
                                </li>
                            );
                        })}

                        {/* { existingVakIcons.map((vakSlug, index) => {
                            return (<li className={`page-klas__klassen__vak vak-icon ${vakSlug}`}><button><span className="vak-icon__name"><span>{vakSlug}</span></span></button></li>);
                        }) };

                        <li className={`page-klas__klassen__vak vak-icon vak-icon--alt vak-icon--alt-1`}><button><span className="vak-icon__name"><span>Alternatief 1</span></span></button></li>
                        <li className={`page-klas__klassen__vak vak-icon vak-icon--alt vak-icon--alt-2`}><button><span className="vak-icon__name"><span>Alternatief 2</span></span></button></li> */}
                    </ul>

                    <TrajectSelectModal show={showTrajectSelect} setShow={setShowTrajectSelect} options={Object.values(currentVakcurKlas.trajecten).map(traject => { return { title: traject.name, slug: traject.slug } })} callback={trajectSelected} />
                </>}

                <video ref={videoEl} src={bgVideo} type="video/mp4" playsInline muted></video>
            </div>
        </div>
    </>);
}

export default PageKlas;