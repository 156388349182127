import React, { useContext } from 'react';

import InfoModal from './InfoModal';
import { UserContext } from '../App';
import { MainContext } from './MainLayoutWrapper';

function SideYearNav({ showInfoModal, setShowInfoModal }) {
    const userContext = useContext(UserContext);
    const mainContext = useContext(MainContext);

    const klasSlug = mainContext.activeFilters.klas;

    const currentVakcurKlas = mainContext.klassen.find(klas => parseInt(klas.slug) === parseInt(klasSlug));
    const nextVakcurKlas = mainContext.klassen.find(klas => parseInt(klas.slug) === parseInt(klasSlug) + 1);
    const prevVakcurKlas = mainContext.klassen.find(klas => parseInt(klas.slug) === parseInt(klasSlug) - 1);

    function navigateToKlas(klasSlug) {
        mainContext.helperFunctions.setKlasFilter(klasSlug, false, false);
    }

    function closeKlasInfoModal() {
        const currentUser = userContext.getCurrentUser();
        const value = localStorage.getItem(`u${currentUser.id}_vakcurriculum_klas_info_modal_views`) ? localStorage.getItem(`u${currentUser.id}_vakcurriculum_klas_info_modal_views`).split(',') : [];

        if (value.includes(klasSlug)) return;
        localStorage.setItem(`u${currentUser.id}_vakcurriculum_klas_info_modal_views`, [...value, klasSlug]);
    }

    return (<>
        {currentVakcurKlas && <>
            <div className="page-klas__side-nav side-year-nav">
                {nextVakcurKlas
                    ? <button className="side-year-nav__btn side-year-nav__btn--next" onClick={() => navigateToKlas(nextVakcurKlas.slug)} >{nextVakcurKlas.slug}</button>
                    : <button className="side-year-nav__btn side-year-nav__btn--next" disabled={true}>&nbsp;</button>
                }

                <div className={'side-year-nav__spacer' + (!!nextVakcurKlas ? ' side-year-nav__spacer--visible' : '')}></div>

                <div className="side-year-nav__current">
                    {nextVakcurKlas
                        ? <button className="side-year-nav__current__up" onClick={() => navigateToKlas(!!nextVakcurKlas ? nextVakcurKlas.slug : null)}>Omhoog</button>
                        : <button className="side-year-nav__current__up" disabled={true}>Omhoog</button>
                    }

                    <span className="side-year-nav__btn side-year-nav__btn--current">
                        <span>{currentVakcurKlas.slug}</span>
                        <button className="side-year-nav__year-info-btn" onClick={() => setShowInfoModal(true)}>i</button>
                    </span>

                    {prevVakcurKlas
                        ? <button className="side-year-nav__current__down" onClick={() => navigateToKlas(prevVakcurKlas.slug)}>Omlaag</button>
                        : <button className="side-year-nav__current__down" disabled={true}>Omlaag</button>
                    }
                </div>

                <div className={'side-year-nav__spacer' + (!!prevVakcurKlas ? ' side-year-nav__spacer--visible' : '')}></div>

                {prevVakcurKlas
                    ? <button className="side-year-nav__btn side-year-nav__btn--prev" onClick={() => navigateToKlas(!!prevVakcurKlas ? prevVakcurKlas.slug : null)}>{prevVakcurKlas.slug}</button>
                    : <button className="side-year-nav__btn side-year-nav__btn--prev" disabled={true}>&nbsp;</button>
                }
            </div>

            <InfoModal title={`Ontwikkelingsmoment van <span class="lowercase-first">${currentVakcurKlas.name}</span>`} desc={currentVakcurKlas.description} show={showInfoModal} setShow={setShowInfoModal} onClose={closeKlasInfoModal} />
        </>}
    </>);
}

export default SideYearNav;