import React, { useState, useEffect, useContext, useCallback, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../App';

import { useGSAP } from '@gsap/react';
import ScrollTrigger from 'gsap/ScrollTrigger';

import setFullscreenVideoAspectRatio from '../helpers/VideoHelper';
import bgVideo from './../assets/videos/vs_pedagogiek_bg.mp4';
import CardContentDisplay from '../components/CardContentDisplay';
import { MainContext } from '../components/MainLayoutWrapper';

gsap.registerPlugin(ScrollTrigger);

function PageVsPedagogiekCard({ settings }) {
    const mainContext = useContext(MainContext);
    const userContext = useContext(UserContext);
    const params = useParams();

    const navigate = useNavigate();

    const cardEl = useRef(null);
    const videoEl = useRef(null);

    const [card, setCard] = useState(null);
    const slug = params.slug || null;

    function resize() {
        if (videoEl.current === null) return;
        setFullscreenVideoAspectRatio(videoEl.current, 16 / 9);
    }
    resize();

    function setVideoEl(el) {
        videoEl.current = el;

        if (el === null) return;
        resize();
    }

    useLayoutEffect(() => {
        if (videoEl.current === null) return;

        resize();
        window.addEventListener('resize', resize);

        const interval = setInterval(() => {
            if (videoEl.current.currentTime > 6) {
                videoEl.current.currentTime = 2;
            }
        }, 100);

        return () => {
            window.removeEventListener('resize', resize);
            clearInterval(interval);
        }

    }, [card]);

    const getCardTitle = useCallback(() => {
        return card.name;
    }, [card]);

    const getCardSubtitle = useCallback(() => {
        return card.lpb_type;
    }, [card]);

    function scrollDown() {
        window.scrollTo({
            top: window.innerHeight / 2,
            behavior: 'smooth'
        });
    }

    function close() {
        navigate('/vs-pedagogiek');
    }

    useGSAP(() => {
        if (!cardEl.current) return;

        gsap.timeline({
            ease: 'linear',
            scrollTrigger: {
                trigger: cardEl.current,
                start: 0,
                end: window.innerHeight * 0.2,
                scrub: 0.5
            }
        })
            .to('.jsInner', { duration: 1, marginInline: '0px' })
            .to('.jsCloseBtn', { duration: 1, left: 60 }, '-=1')
            .to('.jsImg', { duration: 1, width: '15vw' }, '-=1')
            .to('.jsHeader', { duration: 1, minHeight: 0 }, '-=1')
            .to('.jsDownBtn', { duration: 1, translateY: -100, width: 0, height: 0, opacity: 0 }, '-=1');
    }, { dependencies: [card], scope: cardEl });

    useEffect(() => {
        if (slug === null) return;
        if (!userContext.fetchHeaders) return;

        try {
            fetch(settings.apiBaseUrl + 'vs-pedagogiek/kaart', {
                method: 'POST',
                headers: userContext.fetchHeaders,
                body: JSON.stringify({ kaartSlug: slug })
            })
                .then(result => result.json())
                .then(response => {
                    if (response.success) {
                        setCard(response.kaart);
                    }
                    else {
                        console.error('Error getting card', response);

                        if (response.message === 'invalid-card') navigate('/vs-pedagogiek');
                        alert('Oeps, er ging iets niet helemaal goed. Herlaad de pagina en probeer het nogmaals.');
                    }
                });
        } catch (error) {
            console.error('Error getting card', error);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug, setCard, settings.apiBaseUrl, userContext.fetchHeaders]);

    useEffect(() => {
        if (card === null) return;
        mainContext.addHistoryCard(card);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [card, getCardTitle, getCardSubtitle]);

    return (<>
        {!!card ?
            <article className="card" ref={cardEl}>
                <div className="card__inner jsInner">
                    <button className="card__close-btn jsCloseBtn" onClick={close}>Sluiten</button>

                    <header className="card__header jsHeader">
                        <h1 className="card__title">{getCardTitle()}</h1>
                        <div className="card__subtitle">{getCardSubtitle(card)}</div>

                        {!!card.featured_image.thumbnail
                            ? <div className="card__img jsImg" style={{ backgroundImage: `url(${card.featured_image.thumbnail})` }}></div>
                            : <div className="card__img card__img--no-image jsImg"></div>
                        }

                        <button className="card__down-btn jsDownBtn" onClick={scrollDown}>Naar beneden</button>
                    </header>

                    <div className="card__content-wrapper">
                        <div className="card__content-wrapper__inner">
                            <CardContentDisplay contentItems={card.content_items} />
                        </div>
                    </div>
                </div>

                <div className="card__bg-wrapper">
                    <video ref={setVideoEl} key={Math.random() + Math.random() + ''} src={bgVideo + '#t=2.3,6'} type="video/mp4" autoPlay playsInline muted></video>
                </div>
            </article>
            :
            <div className="loading-msg">Laden..</div>
        }
    </>);
}

export default PageVsPedagogiekCard;