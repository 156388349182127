import React, { useEffect, useCallback, useState } from 'react';
import VakcurListCard from './VakcurListCard';
import MenskundeListCard from './MenskundeListCard';
import VsPedagogiekListCard from './VsPedagogiekListCard';
import OntwikkelingsmomentListCard from './OntwikkelingsmomentListCard';

function CardSelectModal({ show, setShow, cards, subtopics = [] }) {
    const [viewSubTopicIndex, setViewSubTopicIndex] = useState(null);

    const keyUp = useCallback((e => {
        if (e.key.toLowerCase() === 'escape') setShow(false);
    }), [setShow]);

    const renderSwitch = (card) => {
        switch (card.lpb_type) {
            case 'vakcurriculum': return (<VakcurListCard card={card} callback={() => setShow(false)} />);
            case 'vs-pedagogiek': return (<VsPedagogiekListCard card={card} callback={() => setShow(false)} />);
            case 'ontwikkelingsmoment': return (<OntwikkelingsmomentListCard card={card} callback={() => setShow(false)} />);
            default: return (<MenskundeListCard card={card} callback={() => setShow(false)} />);
        }
    }

    useEffect(() => {
        setViewSubTopicIndex(null);
    }, [show]);

    useEffect(() => {
        window.addEventListener('keyup', keyUp);
        return () => window.removeEventListener('keyup', keyUp);
    }, [keyUp]);

    return (
        <div className={'card-select-modal' + (show ? ' open' : '')}>
            <div className="card-select-modal__bg" onClick={() => setShow(false)}></div>

            <div className="card-select-modal__inner">
                <button type="button" className="card-select-modal__close-btn" onClick={() => setShow(false)}>Sluiten</button>

                {/* Show subtopics if any */}
                {(!!subtopics.length && viewSubTopicIndex === null) &&
                    <ul className="card-select-modal__topics">
                        {subtopics.map((topic, index) => {
                            return (
                                <li key={Math.random() + Math.random()} className="card-select-modal__topic">
                                    <button type="button" onClick={() => setViewSubTopicIndex(index)}>
                                        {topic.name}
                                        <span className="counter">{topic.cards.length}</span>
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                }

                {/* Show cards inside selected subtopic */}
                {(subtopics.length > 0 && viewSubTopicIndex > -1 && typeof subtopics[viewSubTopicIndex] !== 'undefined') && <>
                    <button type="button" className="card-select-modal__back-btn" onClick={() => setViewSubTopicIndex(null)}>Terug naar onderwerpen</button>

                    <ul className="card-select-modal__cards">
                        {subtopics[viewSubTopicIndex]['cards'].map(card =>
                            <li key={Math.random() + Math.random() + ''}>
                                {renderSwitch(card)}
                            </li>
                        )}
                    </ul>
                </>}

                {/* Show cards when there are no subtopics */}
                {(subtopics.length === 0) &&
                    <ul className="card-select-modal__cards">
                        {cards.map(card =>
                            <li key={Math.random() + Math.random() + ''}>
                                {renderSwitch(card)}
                            </li>
                        )}
                    </ul>
                }
            </div>
        </div>
    );
}

export default CardSelectModal;