import { useContext } from 'react';
import { MainContext } from './MainLayoutWrapper';

function CardContentDisplay({ contentItems }) {
    const mainContext = useContext(MainContext);

    const handleItemClick = (e) => {
        if (typeof e.target.dataset.link !== 'undefined') {
            const searchParams = new URLSearchParams(e.target.dataset.link);
            const type = searchParams.get('type');
            const area = searchParams.get('area');

            if (type === 'card') {
                switch (area) {
                    case 'lpb_area_vakcur' : mainContext.helperFunctions.nav(`/vakcurriculum/kaart/${searchParams.get('slug')}`); break;
                    case 'lpb_area_methdid' : mainContext.helperFunctions.nav(`/methodisch-didactisch/kaart/${searchParams.get('slug')}`); break;
                    case 'lpb_area_mensk' : mainContext.helperFunctions.nav(`/menskunde/kaart/${searchParams.get('slug')}`); break;
                    case 'lpb_area_ontwikm' : mainContext.helperFunctions.nav(`/ontwikkelingsmoment/kaart/${searchParams.get('slug')}`); break;
                    case 'lpb_area_vsdidac' : mainContext.helperFunctions.nav(`/vs-pedagogiek/kaart/${searchParams.get('slug')}`); break;
                    default : return;
                }
            }
            else if (type === 'search') {
                mainContext.helperFunctions.setShowSearchOverlay(true);
                mainContext.helperFunctions.setSearchQuery(searchParams.get('query'));
                

                switch (area) {
                    case 'lpb_area_vakcur' : mainContext.helperFunctions.setSearchScope('vakcurriculum'); break;
                    case 'lpb_area_mensk' : mainContext.helperFunctions.setSearchScope('menskunde'); break;
                    case 'lpb_area_vsdidac' : mainContext.helperFunctions.setSearchScope('vs-pedagogiek'); break;
                    case 'lpb_area_ontwikm' : mainContext.helperFunctions.setSearchScope('ontwikkelingsmoment'); break;
                    // case 'lpb_area_methdid' : mainContext.helperFunctions.setSearchScope('methodisch-didactisch'); break;

                    default : mainContext.helperFunctions.setSearchScope('global'); break;
                }
            }
        }
    }


    return (
        contentItems.map(contentItem => {
            switch (contentItem.type) {
                case 'subtitle':
                    return (<section className="card__section__subtitle" key={Math.random() + Math.random() + ''}>{contentItem.title}</section>);

                case 'title-content':
                    return (<section key={Math.random() + Math.random() + ''} className="card__section card__section--title-content" onClick={handleItemClick}>
                        {!!contentItem.title && <span className="card__section__title heading:3">{contentItem.title}</span>}
                        <div className="card__section__content default-styles" dangerouslySetInnerHTML={{ __html: contentItem.content }}></div>
                    </section>);

                case 'image':
                    return (<section key={Math.random() + Math.random() + ''} className="card__section card__section--image">
                        <div className="card__section__img">
                            <img src={contentItem.image.medium} alt="" />
                        </div>
                    </section>);

                case 'summary':
                    return (<section key={Math.random() + Math.random() + ''} className="card__section card__section--summary" onClick={handleItemClick}>
                        <ul>
                            {contentItem.rows.map(item => {
                                return (<li key={Math.random() + Math.random() + ''}>
                                    <div className="title">{item.title}</div>
                                    <div className="content default-styles" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                </li>);
                            })}
                        </ul>
                    </section>);

                default: return null;
            }
        })
    );
}

export default CardContentDisplay;