import React, { useRef, useState, useCallback, useContext, useEffect, useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import gsap from 'gsap';

import bgVideo from './../assets/videos/ontwikkelingsmoment_bg.mp4';
import setFullscreenVideoAspectRatio from '../helpers/VideoHelper';

import { OntwikkelingsmomentContext } from '../components/OntwikkelingsmomentLayoutWrapper';
import { UserContext } from '../App';
import CardSelectModal from '../components/CardSelectModal';

function PageOntwikkelingsmoment() {
    const userContext = useContext(UserContext);
    const { years } = useContext(OntwikkelingsmomentContext);

    const navigate = useNavigate();

    const [activeYearGroup, _setActiveYearGroup] = useState(1);
    const [activeYear, setActiveYear] = useState(null);
    const [viewIsReady, setViewIsReady] = useState(false);

    const targetsEl = useRef(null);
    const topicMenuEl = useRef(null);
    const videoEl = useRef(null);
    const ringsEl = useRef(null);
    const yearEls = useRef([]);

    const [showTopicsList, setShowTopicsList] = useState(false);

    const [showCardSelectModal, setShowCardSelectModal] = useState(false);
    const [cardSelectCards, setCardSelectCards] = useState([]);
    const [cardSelectTopics, setCardSelectTopics] = useState([]);

    const handleOutsideTopicMenuClick = useCallback(e => {
        if (topicMenuEl.current && !topicMenuEl.current.contains(e.target)) {
            setShowTopicsList(false);
        }
    }, []);

    function setActiveYearGroup(yearGroup) {
        _setActiveYearGroup(yearGroup);
        setActiveYear(null);
        staggerYears();
    }

    function keyUpEvent(e) {
        if (e.key.toLowerCase() === 'escape') {
            setShowTopicsList(false);
        }
    }

    function resize() {
        if (videoEl.current === null) return;
        setFullscreenVideoAspectRatio(videoEl.current, 16 / 9);
        setFullscreenVideoAspectRatio(targetsEl.current, 16 / 9);
    }
    resize();

    function activateYear(year) {
        if (!viewIsReady) return;

        setActiveYear(year);
        showYearAnimation(year);
    }

    function showYearAnimation(year) {
        const yearEl = yearEls.current[year - 1];
        if (! yearEl) return;

        gsap.timeline()
            .set(yearEl, { opacity: 1 })

            .add(() => {
                yearEl.classList.add('f1');
            })
            .add(() => {
                yearEl.classList.remove('f1');
                yearEl.classList.add('f2');
            }, '+=0.07')
            .add(() => {
                yearEl.classList.remove('f2');
                yearEl.classList.add('f3');
            }, '+=0.07')

            .set(yearEl, { clearProps: 'opacity' });
    }

    function deactivateYear() {
        setActiveYear(null);
    }

    function openYear(year) {
        setTimeout(() => {
            const actualYear = (7 * (activeYearGroup - 1)) + year;
            if (typeof years[actualYear] === 'undefined') return alert('Dit leerjaar is nog niet beschikbaar.');

            const yearObject = years[actualYear];

            setCardSelectCards(yearObject.topics.length === 0 ? yearObject.cards : []);
            setCardSelectTopics(yearObject.topics.length > 0 ? yearObject.topics : []);
            setShowCardSelectModal(true);
        }, year === activeYear ? 0 : 400);
    }

    const staggerYears = useCallback(() => {
        const stagger = 0.08;

        gsap.timeline()
            .add(() => {showYearAnimation(1)}, '+=0.2')
            .add(() => {showYearAnimation(2)}, `+=${stagger}`)
            .add(() => {showYearAnimation(3)}, `+=${stagger}`)
            .add(() => {showYearAnimation(4)}, `+=${stagger}`)
            .add(() => {showYearAnimation(5)}, `+=${stagger}`)
            .add(() => {showYearAnimation(6)}, `+=${stagger}`)
            .add(() => {showYearAnimation(7)}, `+=${stagger}`)
            .add(() => {showYearAnimation(6)}, `+=${stagger}`)
            .add(() => {showYearAnimation(5)}, `+=${stagger}`)
            .add(() => {showYearAnimation(4)}, `+=${stagger}`)
            .add(() => {showYearAnimation(3)}, `+=${stagger}`)
            .add(() => {showYearAnimation(2)}, `+=${stagger}`)
            .add(() => {showYearAnimation(1)}, `+=${stagger}`)
        ;
    }, []);

    useLayoutEffect(() => {
        if (videoEl.current === null) return;

        resize();
        window.addEventListener('resize', resize);

        const interval = setInterval(() => {
            if (videoEl.current.currentTime > 5.8) {
                videoEl.current.currentTime = 2.3;
            }
        }, 100);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('keyup', keyUpEvent);
        return () => window.removeEventListener('keyup', keyUpEvent);
    }, [userContext.isLoggedIn, navigate]);

    useEffect(() => {
        document.addEventListener('mouseup', handleOutsideTopicMenuClick);
        return () => document.removeEventListener('mouseup', handleOutsideTopicMenuClick);
    }, [handleOutsideTopicMenuClick]);

    useEffect(() => {
        if (viewIsReady) staggerYears();
    }, [viewIsReady, staggerYears]);

    useEffect(() => {
        if (years.length === 0) return;

        videoEl.current.play();
        setTimeout(() => setViewIsReady(true), 1600);
    }, [years]);

    return (<>
        <div className="ontwikkelingsmoment-years">
            {[...Array(5).keys()].map((year) => {
                return (<button key={`year-group-${year + 1}`} type="button" onClick={() => setActiveYearGroup(year + 1)} className={`ontwikkelingsmoment-years__year btn btn--text-light` + (activeYearGroup === (year + 1) ? ' btn--green' : '')}>Jaar {(year * 7 + 1)} t/m {(year + 1) * 7}</button>);
            })}
        </div>

        <div className="ontwikkelingsmoment-topics">
            {!!years.length &&
                <div className={'ontwikkelingsmoment-topics__topic-menu topic-menu' + (showTopicsList ? ' open' : '')} ref={topicMenuEl}>
                    <div className="top-spacer"></div>

                    <div className="topic-menu__inner">
                        <button className="topic-menu__btn" type="button" onClick={() => { setShowTopicsList(!showTopicsList) }}>Topics</button>

                        <ul className="topic-menu__list">
                            <li className="title">Alle onderwerpen</li>

                            {years.map((topic, index) => {
                                return <li className={'heading:3' + (index === 0 ? ' first' : '')} key={`topic-${index}`}><Link to={'/ontwikkelingsmoment/topics/' + topic.slug}>{topic.name}</Link></li>;
                            })}
                        </ul>
                    </div>
                </div>
            }

            <div className={`ontwikkelingsmoment-topics__targets ${viewIsReady ? ' is-ready' : ''}`} ref={targetsEl}>
                {[7, 6, 5, 4, 3, 2, 1].map(year => {
                    return (<button key={`ontwikm-year-target-${year}`} onClick={() => openYear(year)} onMouseEnter={() => activateYear(year)} onMouseLeave={deactivateYear} type="button" className={`ontwikkelingsmoment-topics__target target-${year}`}>
                        <span className="year">{((activeYearGroup - 1) * 7) + year}</span></button>);
                })}
            </div>

            <div className={'ontwikkelingsmoment-topics__rings' + (years.length ? ' visible' : '')} ref={ringsEl}>
                {[1, 2, 3, 4, 5, 6, 7].map(year => {
                    return (<div ref={el => yearEls.current[year - 1] = el} key={`ontwikm-year-${year}`} className={`ontwikkelingsmoment-topics__year year-${year}` + (activeYear === year ? ' active' : '')}><div className="first"></div><div className="second"></div><div className="third"></div></div>);
                })}
            </div>

            <div className="ontwikkelingsmoment-topics__bg-wrapper">
                <video ref={videoEl} src={bgVideo} type="video/mp4" playsInline muted></video>
            </div>

            <CardSelectModal show={showCardSelectModal} setShow={setShowCardSelectModal} cards={cardSelectCards} subtopics={cardSelectTopics} />
        </div>
    </>);
}

export default PageOntwikkelingsmoment;