import React, { useEffect, useState, useContext, createContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { UserContext } from '../App';
import MainLayoutWrapper from './MainLayoutWrapper';
import { getLoginToken } from './../helpers/UserHelper';

export const OntwikkelingsmomentContext = createContext(null);

function OntwikkelingsmomentLayoutWrapper({ settings }) {
    const userContext = useContext(UserContext);
    const location = useLocation();

    const [ontwikkelingsmomentYears, setOntwikkelingsmomentYears] = useState([]);

    useEffect(() => {
        if (getLoginToken() === null || userContext.fetchHeaders === null || ontwikkelingsmomentYears.length > 0) return;

        try {
            fetch(settings.apiBaseUrl + 'ontwikkelingsmoment/general', {
                method: 'POST',
                headers: userContext.fetchHeaders,
            })
                .then(result => result.json())
                .then(response => {
                    if (!response.success) return;
                    setOntwikkelingsmomentYears(response.ontwikkelingsmoment.years);
                });
        } catch (error) {
            console.error(error);
        }
    }, [userContext.fetchHeaders, settings.apiBaseUrl, location.pathname, ontwikkelingsmomentYears.length]);

    const getTopic = function(topicSlug) {
        return ontwikkelingsmomentYears.find(topic => topic.slug === topicSlug) || null;
    };

    return (
        <OntwikkelingsmomentContext.Provider value={{
            years: ontwikkelingsmomentYears,
            getTopic
        }}>
            <MainLayoutWrapper settings={settings}>
                <Outlet key={location.pathname} />
            </MainLayoutWrapper>
        </OntwikkelingsmomentContext.Provider>
    );
}

export default OntwikkelingsmomentLayoutWrapper;