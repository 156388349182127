import React from 'react';
import { useNavigate } from 'react-router-dom';

function VakcurListCard({ card, callback = null, showThumbnail = true, altBgColor = false }) {
    const navigate = useNavigate();

    function goToCard(card) {
        if (callback !== null) callback();
        navigate(`/vakcurriculum/kaart/${card.slug}`);
    }

    function getCardTitle() {
        if (card.thema) return card.thema;
        // if (card.tijdvakken_format.length) return `Tijdvak ${card.tijdvakken_format.join(', ')}`;
        // if (card.vakken_format.length) return card.vakken_format.join(', ');

        return card.name;
    }

    function getCardSubtitle() {
        return '';

        // return 'unknown';
        // return card.vakken_format.join(', ');
    }

    function getCardMeta() {
        let string = '';

        if (typeof card.vakken_format !== 'undefined' && card.vakken_format.length) {
            string += card.vakken_format.join(', ');
        }

        if (typeof card.klassen !== 'undefined' && card.klassen.length) {
            if (string.length) string += ' – ';
            string += 'Klas ' + card.klassen.join(', ');
        }

        return string;
    }

    return (
        <button type="button" className={'list-card list-card--vakcurriculum' + (!showThumbnail ? ' list-card--no-thumbnail' : '') + (!!altBgColor ? ' list-card--alt' : '')} onClick={() => goToCard(card)}>
            {showThumbnail && <>
                {!!card.featured_image.thumbnail && <div className="list-card__img-wrapper" style={{ backgroundImage: `url(${card.featured_image.thumbnail})` }}></div>}
                {!card.featured_image.thumbnail && <div className="list-card__img-wrapper list-card__img-wrapper--no-image"></div>}
            </>}

            <div className="list-card__content-wrapper">
                {getCardSubtitle() && <span className="list-card__subtitle">{getCardSubtitle()}</span>}
                <span className="list-card__title heading:3">{getCardTitle()}</span>
                <span className="list-card__meta">{getCardMeta()}</span>
                <div className="list-card__excerpt default-styles"><p>{card.excerpt}</p></div>
                <div className="list-card__icon"></div>
            </div>
        </button>
    );
}

export default VakcurListCard;