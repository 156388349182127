import React, { useState, useEffect, useCallback } from 'react';
import VakcurListCard from './VakcurListCard';
import VsPedagogiekListCard from './VsPedagogiekListCard';
import OntwikkelingsmomentListCard from './OntwikkelingsmomentListCard';
import MenskundeListCard from './MenskundeListCard';

function HistoryModal({ cards }) {
    const [show, setShow] = useState(false);

    const keyUp = useCallback(e => {
        if (e.key.toLowerCase() === 'escape') hide();
    }, []);

    function toggle() {
        if (show) hide();
        else setShow(true);
    }

    function hide() {
        setShow(false);
    }

    useEffect(() => {
        window.addEventListener('keyup', keyUp);
        return () => window.removeEventListener('keyup', keyUp);
    }, [keyUp]);

    return (<>
        {!!cards.length && <>
            <button className={'vakcur-history-modal-trigger' + (show ? ' open' : '')} onClick={toggle}>Geschiedenis</button>

            <div className={'vakcur-history-modal' + (show ? ' show' : '')}>
                <div className="vakcur-history-modal__bg" onClick={hide}></div>

                <div className="vakcur-history-modal__inner">
                    <div className="vakcur-history-modal__top">
                        <span>Laatst bekeken</span>
                    </div>

                    <div className="vakcur-history-modal__content-wrapper">
                        {cards.map(card => {
                            switch(card.lpb_type) {
                                case 'vs-pedagogiek' : return <VsPedagogiekListCard key={`search-result-card-${card.id}`} card={card} callback={() => setShow(false)} showThumbnail={false} altBgColor={true} />
                                case 'ontwikkelingsmoment' : return <OntwikkelingsmomentListCard key={`search-result-card-${card.id}`} card={card} callback={() => setShow(false)} showThumbnail={false} altBgColor={true} />
                                case 'menskunde' : return <MenskundeListCard key={`search-result-card-${card.id}`} card={card} callback={() => setShow(false)} showThumbnail={false} altBgColor={true} />
                                default : return <VakcurListCard key={`search-result-card-${card.id}`} card={card} callback={() => setShow(false)} showThumbnail={false} altBgColor={true} />
                            }
                        })}
                    </div>
                </div>
            </div>
        </>}
    </>);
}

export default HistoryModal;