import React, { useRef, useState, useCallback, useContext, useEffect, useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import gsap from 'gsap';

import bgVideo from './../assets/videos/vs_pedagogiek_bg.mp4';
import setFullscreenVideoAspectRatio from '../helpers/VideoHelper';

import { VsPedagogiekContext } from '../components/VsPedagogiekLayoutWrapper';
import { UserContext } from '../App';
import CardSelectModal from '../components/CardSelectModal';

function PageVsPedagogiek() {
    const userContext = useContext(UserContext);
    const { topics } = useContext(VsPedagogiekContext);

    const navigate = useNavigate();

    const [activeHoverTitle, setActiveHoverTitle] = useState('Laden..');

    const topicMenuEl = useRef(null);
    const videoEl = useRef(null);
    const cloudsEl = useRef(null);

    const [showTopicsList, setShowTopicsList] = useState(false);

    const [showCardSelectModal, setShowCardSelectModal] = useState(false);
    const [cardSelectCards, setCardSelectCards] = useState([]);
    const [cardSelectTopics, setCardSelectTopics] = useState([]);

    const handleOutsideTopicMenuClick = useCallback(e => {
        if (topicMenuEl.current && !topicMenuEl.current.contains(e.target)) {
            setShowTopicsList(false);
        }
    }, []);

    function keyUpEvent(e) {
        if (e.key.toLowerCase() === 'escape') {
            setShowTopicsList(false);
        }
    }

    function onTopicClick(topic) {
        setCardSelectCards(topic.subtopics.length === 0 ? topic.cards : []);
        setCardSelectTopics(topic.subtopics.length > 0 ? topic.subtopics : []);
        setShowCardSelectModal(true);
    }

    function resize() {
        if (videoEl.current === null) return;
        setFullscreenVideoAspectRatio(videoEl.current, 16 / 9);
    }

    resize();

    useLayoutEffect(() => {
        if (videoEl.current === null) return;

        resize();
        window.addEventListener('resize', resize);

        const interval = setInterval(() => {
            if (videoEl.current.currentTime > 6) {
                videoEl.current.currentTime = 2.3;
            }
        }, 100);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('keyup', keyUpEvent);
        return () => window.removeEventListener('keyup', keyUpEvent);
    }, [userContext.isLoggedIn, navigate]);

    useEffect(() => {
        document.addEventListener('mouseup', handleOutsideTopicMenuClick);
        return () => document.removeEventListener('mouseup', handleOutsideTopicMenuClick);
    }, [handleOutsideTopicMenuClick]);

    useEffect(() => {
        if (topics.length === 0) return;

        gsap.set(cloudsEl.current, { opacity: 0 });

        videoEl.current.play();
        setActiveHoverTitle('');

        const context = gsap.context(() => {
            gsap.timeline({ delay: 0.5 })
                .set('.jsCloud', { yPercent: -100 })
                .set(cloudsEl.current, { opacity: 1 })
                .to('.jsCloud', { duration: 0.5, opacity: 1, ease: 'power2.out' })
                .to('.jsCloud', { duration: 2, yPercent: 0, ease: 'power2.out' }, '-=0.5')
                .set('.jsCloud', { clearProps: 'yPercent' });
        }, cloudsEl);

        return () => context.revert();
    }, [topics]);

    return (<>
        <div className="vs-pedagogiek-topics">
            {!!topics.length &&
                <div className={'vs-pedagogiek-topics__topic-menu topic-menu' + (showTopicsList ? ' open' : '')} ref={topicMenuEl}>
                    <div className="top-spacer"></div>

                    <div className="topic-menu__inner">
                        <button className="topic-menu__btn" type="button" onClick={() => { setShowTopicsList(!showTopicsList) }}>Topics</button>

                        <ul className="topic-menu__list">
                            <li className="title">Alle onderwerpen</li>

                            {topics.map((topic, index) => {
                                return <li className={'heading:3' + (index === 0 ? ' first' : '')} key={`topic-${index}`}><Link to={'/vs-pedagogiek/topics/' + topic.slug}>{topic.name}</Link></li>;
                            })}
                        </ul>
                    </div>
                </div>
            }

            <div className={'vs-pedagogiek-topics__clouds' + (topics.length ? ' visible' : '')} ref={cloudsEl}>
                {topics.map((topic, index) => {
                    return (
                        <div key={`topic-${index}`} className={'vs-pedagogiek-topics__cloud jsCloud topic-' + (index + 1) + (activeHoverTitle === topic.name ? ' active' : '')}>
                            <button type="button" onClick={() => onTopicClick(topic)} onMouseOver={() => setActiveHoverTitle(topic.name)}>{topic.name}</button>
                        </div>
                    );
                })}

                <span className="vs-pedagogiek-topics__hover-title">{activeHoverTitle}</span>
            </div>

            <div className="vs-pedagogiek-topics__bg-wrapper">
                <video ref={videoEl} src={bgVideo} type="video/mp4" playsInline muted></video>
            </div>

            <CardSelectModal show={showCardSelectModal} setShow={setShowCardSelectModal} cards={cardSelectCards} subtopics={cardSelectTopics} />
        </div>
    </>);
}

export default PageVsPedagogiek;